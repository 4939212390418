<template>
  <div class="quota__details">
    <div
      v-for="item in quota.child_quotas"
      :key="item.id"
      class="quota__details-item"
    >
      <div class="quota__main mod-child">
        {{ getSupplierTitle(item.suppliers_ids[0]) }}
      </div>
      <div class="quota__progressbar">
        <div class="quota__progressbar-info">
          <div :class="{ overflow: item.count > item.trucks_allowed_count }">
            {{ getProgressBarParams(item).title }}
          </div>
          <div class="info__value">
            {{ item.count }}/{{ item.trucks_allowed_count }}
          </div>
        </div>

        <el-progress
          :text-inside="true"
          :stroke-width="progressWidth"
          :percentage="getProgressBarParams(item).percentage"
          :color="getProgressBarParams(item).color"
        />
      </div>
    </div>
    <div class="quota__details-item">
      <div class="quota__main mod-child">
        <div>
          <span>Нераспределенные таймслоты:&nbsp;</span>
          <span class="title__unimproved">{{ getUnimprovedQuota }}</span>
        </div>
      </div>
      <div class="quota__progressbar" />
    </div>
    <div v-if="!isMobile && !isExporter" class="quota__details-button">
      <iq-button color="main" size="small" @onClick="goToTimeslots">
        Показать ТС
      </iq-button>
    </div>
  </div>
</template>

<script>
import { GET_IS_MOBILE } from '@/store/actions'
import { GET_SUPPLIERS_ALL } from '@/views/exporter/store/actions'
import { SET_TIMESLOTS_QUOTA_FILTER } from '@/views/manager/store/actions'
import { getProgressBarParams } from '@/core'
import { mapGetters, mapMutations } from 'vuex'
import IqButton from '@/views/ui/components/buttons/IqButton.vue'

export default {
  name: 'QuotaShared',
  components: { IqButton },
  props: {
    quota: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      getProgressBarParams,
    }
  },
  computed: {
    ...mapGetters({
      suppliersList: GET_SUPPLIERS_ALL,
      isMobile: GET_IS_MOBILE,
    }),
    getUnimprovedQuota() {
      let improvedQuota = this.quota.child_quotas.reduce(
        (result, item) => result + item.trucks_allowed_count,
        0,
      )

      return this.quota.trucks_allowed_count - improvedQuota > 0
        ? this.quota.trucks_allowed_count - improvedQuota
        : 0
    },
    progressWidth() {
      return this.isMobile ? 8 : 16
    },
  },
  methods: {
    ...mapMutations({
      setQuotaFilter: SET_TIMESLOTS_QUOTA_FILTER,
    }),
    getSupplierTitle(id) {
      let supplier = this.suppliersList.find(item => item.id === id)

      return supplier ? `Поставщик: ${supplier.name}` : 'Общая квота'
    },
    goToTimeslots() {
      this.setQuotaFilter(this.quota.id)
      this.$router.push({ name: 'Control-terminal' })
    },
  },
}
</script>

<style lang="sass" scoped>
.quota__details
  display: flex
  flex-direction: column
  gap: 18px
  margin: 18px 0 18px 27px
  @media (max-width: 1200px)
    margin: 16px 0
    padding: 14px
    background: $color-main-background
    border-radius: 8px
    .mod-child
      flex: 0
      width: 100%
      margin-right: 0
      margin-bottom: 10px
      padding: 0
      background: transparent
      border: none
    .quota__progressbar
      flex: 0
      width: 100%
      &-info
        justify-content: space-between
        font-size: 12px

  &-item
    display: flex
    justify-content: space-between
    align-items: center
    @media (max-width: 1200px)
      flex-direction: column

  &-button
    display: flex
    justify-content: flex-end
</style>
